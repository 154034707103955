<template>
  <div class="container-fluid">
    <h1>{{ txttitre }}</h1>
    <div class="row">
      <div class="col-md-offset-1 col-md-1">
        <label for="dt">Date</label>
        <div class="input-group">
          <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
          <datepicker lang="fr" v-model="action.dt"></datepicker>
        </div>
      </div>

      <div class="col-md-offset-1 col-md-1">
        <label for="dt">Echéance</label>
        <div class="input-group">
          <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
          <datepicker lang="fr" v-model="action.dateecheance"></datepicker>
        </div>
      </div>

      <div class="col-md-offset-1 col-md-3">
        <label for="sujet">Sujet</label>
        <div class="input-group">
          <span class="input-group-addon"
            ><i class="fa fa-newspaper-o"></i
          ></span>
          <input
            type="text"
            v-model="action.sujet"
            class="form-control"
            id="sujet"
            placeholder="Sujet"
          />
        </div>
      </div>

      <div class="col-md-offset-1 col-md-1">
        <label for="dt">Soldée</label>
        <div class="input-group">
          <input type="checkbox" v-model="action.solde" class="form-control" id="solde" />
        </div>
      </div>

      <div class="col-md-1">
        <label for="dt">Archivée</label>
        <div class="input-group">
          <input type="checkbox" v-model="action.archive" class="form-control" id="archive" />
        </div>
      </div>

    </div>
    <br />
    <div class="row">
      <div class="form-group">
        <div class="col-md-offset-1 col-md-10">
          <label for="commande.commentaire">Commentaire :</label>
          <textarea
            class="form-control"
            rows="5"
            v-model="action.remarque"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-offset-2 col-md-3">
        <button type="button" class="btn btn-primary" @click="add" v-if="idMaintenance!=0">
          {{ txtfct }}
        </button>
      </div>
    </div>
    <br />
    <div v-if="action.map" class="row">
      <div class="form-group">
        <div class="col-md-offset-1 col-md-10">
            <a :href="action.map" target="_blank"><i class="fa fa-map-marker mark"></i></a>
        </div>
      </div>
    </div> 
    <br />

    <div v-if="action.image" class="row">
      <div class="form-group">
        <div class="col-md-offset-1 col-md-10">
            <a :href="getimage(action.image)" target="_blank"><img :src="getimage(action.image)" width="300" /></a>
        </div>
      </div>
    </div>
    <div v-if="photos.length>0" class="row">
      <div class="form-group" v-for="(photo,index) in photos" :key="index">
        <div class="col-md-offset-1 col-md-10">
            <span @click="viewphoto(photo.photo)"><img :src="photo.photo" width="300" /></span>
        </div>
      </div>
    </div>
    <div class="alert alert-danger" v-if="err">
      <strong><i class="fa fa-warning"></i> Erreur!</strong> {{ msg }}
    </div>
  </div>
</template>

<script>
import store from "../store";
import { executeApi, getPrefix, getPrefixS2T, selectApi } from "../lib/api.js";
import { fla } from "../lib/fct.js";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },
  store: store,
  data() {
    return {
      id: this.$route.params.id,
      idFeu: this.$route.params.feu,
      idReg: this.$route.params.reg,
      idMaintenance: 0,
      err: false,
      msg: "",
      action: {
        dt: moment().format("MM/DD/YYYY"),
        dateecheance: moment().add(6, 'M').format("MM/DD/YYYY"),
        sujet: "",
        remarque: "",
      },
      photos: []
    };
  },
  computed: {
    txttitre: function () {
      return this.id == 0
        ? "Ajouter une action"
        : "Modifier l'action : " + this.id;
    },
    txtfct: function () {
      return this.id == 0 ? "Ajouter" : "Modifier";
    },
  },
  mounted() {
    if (store.state.typeUser == 0) {
      this.$router.push({ name: "login" });
    }

    if (this.id != 0) {
      selectApi(this,"SELECT dt,sujet,remarque,solde,dateecheance,image,latitude,longitude FROM actionpiste WHERE id=" + this.id
      ).then((response) => {
        this.count = response.data.count;
        if (this.count >= 0) {
          this.action = response.data.result[0]
          this.action.solde = (this.action.solde == 1 ? true : false)
          if (this.action.latitude!=0 && this.action.longitude!=0) {
            this.action.map="https://maps.google.com?q=" + this.action.latitude + "," + this.action.longitude;
          } else {
            this.action.map="";
          }
          console.log(this.action.map);
        }
      });

      selectApi(this,"SELECT photo,ext FROM photoaction WHERE idaction=" + this.id
      ).then((response) => {
        this.count = response.data.count
        if (this.count >= 0) {
          this.photos = response.data.result
          console.log(this.photos)
          this.photos.forEach((photo) => {
            photo.photo = "data:image/" + photo.ext.replace('.', '') + ";base64," + photo.photo
          })
          console.log(this.photos)
        }
      });

    }
    let idPiste = store.state.piste;
    let sql = "SELECT id from maintenance WHERE type=4 AND idpiste=" + idPiste + " ORDER BY id DESC LIMIT 1";
      selectApi(this,sql).then((response) => {
        this.count = response.data.count;
        if (this.count > 0) {
          this.idMaintenance = response.data.result[0].id;
        } else {
        sql = "INSERT INTO maintenance SET type=4,dt=NOW(),idpiste=" + idPiste;
        executeApi(this, sql).then(
          (response) => {
            this.idMaintenance = response.data.last;
            },
          (response) => {
              this.idMaintenance = 0;
          })
        }
      });

  },
  methods: {
    getimage: function (img) {
      return getPrefixS2T() + "/app/photo/" + img;
    },
    viewphoto(b64) {
      var image = new Image();
      image.src = b64
      var w = window.open("")
      w.document.write(image.outerHTML)
    },
    add() {
      if (this.action.dt == "" || this.action.sujet == "") {
        this.msg = "Les champs ne peuvent pas être vides.";
        this.err = true;
      } else {
        this.err = false;
        let dt = moment(this.action.dt);
        dt = dt.isValid() ? dt.format("YYYY-MM-DD") : "0000-00-00";
        let dtech = moment(this.action.dateecheance);
        dtech = dtech.isValid() ? dtech.format("YYYY-MM-DD") : "0000-00-00";
        let modif =
          "dt='" + dt + "'" +
          ",dateecheance='" + dtech + "'" +
          ",sujet='" +
          fla(this.action.sujet) +
          "',remarque='" +
          this.action.remarque +
          "',solde='" + (this.action.solde == true ? 1 : 0) +
          "',archive='" + (this.action.archive == true ? 1 : 0) + "'"
        let sql = "";
        if (this.id == 0) {
          sql = "INSERT INTO actionpiste SET client=1,idfeu="+this.idFeu+",idregulateur="+this.idReg+",idmaintenance=" + this.idMaintenance + "," + modif;
        } else {
          sql = "UPDATE actionpiste SET " + modif + " WHERE id=" + this.id;
        }
        executeApi(this, sql).then(
          (response) => {
            this.$router.push({ path: "/actions" });
          },
          (response) => {
            this.msg = "Erreur d'enregistrement.";
            this.err = true;
          }
        );
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sel {
  font-size: 10px;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  overflow-x: hidden;
  overflow-y: auto;
}
.mark {
  font-size: 36px;
  color: red;
}
</style>
